.button {
	display: inline-block;
	background-color: var(--color-primary);
	color: black;
	line-height: 30px;
	border-radius: 5px;
	padding: 5px 15px;
	cursor: pointer;
	white-space: nowrap;
	user-select: none;
	border: none;
	font-size: 1em;
}

.button.inline {
	display: inline-block;
	vertical-align: top;
}

.button.disabled {
	background-color: rgba(255, 255, 255, 0.4);
	pointer-events: none;
}

.button.loading {
	animation: loading 2s infinite ease-in-out;
	pointer-events: none;
	transition: opacity 0.2s;
	opacity: 1;
}

@keyframes loading {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 0.5;
	}
}
