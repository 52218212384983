.header {
	height: 50px;
	width: 100%;
	flex-shrink: 0;
	flex-grow: 0;
	z-index: 100;
	padding: 0 20px;
	display: flex;
	justify-content: center;

	background-color: #18181b;
	box-shadow: rgba(0, 0, 0, 0.9) 0px 1px 2px 0px,
		rgba(0, 0, 0, 0.9) 0px 0px 2px 0px;
}

.inner {
	max-width: 1000px;
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: row;
}

.siteName {
	font-size: 25px;
	font-weight: bold;
}

.link {
	display: inline-block;
	vertical-align: top;
	font-size: 25px;
	margin-left: 1em;
	color: #fff;
}

.link:hover {
	color: var(--color-secondary);
}

.right {
	position: absolute;
	right: 0;
	top: 0;
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.coins {
	margin-right: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.coins:before {
	content: "⬤";
	display: inline-block;
	vertical-align: top;
	font-size: 0.7em;
	color: gold;
	margin-right: 0.4em;
}

.logoutButton {
	display: inline-block;
	background-color: var(--color-primary);
	color: black;
	height: 30px;
	line-height: 30px;
	border-radius: 5px;
	padding: 0 10px;
	cursor: pointer;
	white-space: nowrap;
}

@media screen and (max-width: 600px) {
	.header {
		padding: 0 10px;
	}

	.siteName {
		font-size: 16px;
	}

	.link {
		font-size: 16px;
		margin-left: 0.8em;
	}
}
