.grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 10px;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
}

.item {
	display: block;
	position: relative;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 10px;
}

.item__image {
	width: 100%;
	padding: 28.125% 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}

.item__info {
	padding: 10px;
	color: #fff;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.item__title {
	font-size: 16px;
	width: 100%;
}

.item__price {
	font-size: 14px;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 0;
	flex-shrink: 0;
	margin-left: 5px;
}

.item__price:before {
	content: "⬤";
	display: inline-block;
	vertical-align: top;
	font-size: 0.7em;
	color: gold;
	margin-right: 0.4em;
}

@media screen and (max-width: 900px) {
	.grid {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media screen and (max-width: 650px) {
	.grid {
		grid-template-columns: 1fr 1fr;
		column-gap: 5px;
		row-gap: 5px;
	}

	.item__info {
		display: block;
	}

	.item__title {
		font-size: 14px;
	}

	.item__price {
		margin-top: 5px;
		margin-left: 0;
	}
}
