.app {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.app:global(.darkRed) {
    /* https://www.colourlovers.com/palette/1733762/Close_Up */
    --color-primary: #eb1b3b;
    --color-secondary: #e0dbbd;
    --color-error: #eb1b3b;
}
