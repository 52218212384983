.page {
	padding: 20px;
	overflow: auto;
}

@media screen and (max-width: 600px) {
	.page {
		padding: 5px;
	}
}
