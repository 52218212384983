.page {
	width: 100%;
	height: 100%;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.logo {
	color: var(--color-primary);
	font-size: 30px;
	font-weight: bold;
}

.form {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 10px;
}

.form__input {
	font-size: 20px;
	line-height: 20px;
	border: 0;
	border-radius: 50px;
	padding: 10px 20px;
	margin-top: 10px;
}

.form__submit {
	border-radius: 50px;
	font-size: 20px;
	line-height: 20px;
	padding: 8px 30px;
	background-color: transparent;
	border: 1px solid white;
	color: white;
	transition: background-color 0.5s, color 0.5s;
	margin-top: 10px;
	cursor: pointer;
}

.form__submit:hover {
	background-color: white;
	color: black;
}

.form__submit.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.form__footer_message {
	text-align: center;
	margin-top: 5px;
	max-width: 500px;
}

.form__error {
	margin-top: 5px;
	color: var(--color-error);
	height: 1em;
	line-height: 1em;
}
