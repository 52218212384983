.page {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
}

.item {
	width: 100%;
	max-width: 1040px;
	padding: 20px;
	display: flex;
	flex-direction: row-reverse;
}

.item__imageWrapper {
	flex-shrink: 0;
	flex-grow: 0;
	width: 40%;
}

.item__image {
	display: block;
	width: 100%;
	height: auto;
}

.item__info {
	width: 100%;
	margin-right: 20px;
}

.item__title {
	font-size: 24px;
	font-weight: bold;
}

.item__props {
	margin-top: 15px;
}

.item__prop {
	margin-top: 0.4em;
	font-size: 16px;
}

.item__prop__title {
	opacity: 0.8;
}

.item__description {
	margin-top: 15px;
	max-width: 500px;
}

.item__description__title {
	font-weight: bold;
	margin-bottom: 5px;
}

.item__description__text p:first-child {
	margin-top: 0;
}

.item__description__text p:last-child {
	margin-bottom: 0;
}

.actionButton {
	margin-top: 15px;
}

.form {
	width: 100%;
	max-width: 500px;
	margin-top: 15px;
}

.form__error {
	margin-top: 10px;
	color: var(--color-error);
}

.buyForm__textbox {
	display: block;
	width: 100%;
	height: 5em;
	padding: 10px;
	border-radius: 5px;
	border: 0;
}

.sellForm__textbox {
	display: block;
	width: 100%;
	height: 10em;
	padding: 10px;
	border-radius: 5px;
	border: 0;
}

.purchasedItem {
	margin-top: 20px;
}

.purchasedItem__title {
	font-weight: bold;
	font-size: 20px;
}

.purchasedItem__subtitle {
	margin-top: 5px;
}

.purchasedItem__text {
	max-width: 500px;
	padding: 1em 1.5em;
	background-color: rgba(255, 255, 255, 0.1);
	margin-top: 10px;
}

.purchasedItem__text p {
	overflow-wrap: anywhere;
}

.purchasedItem__text p:first-child {
	margin-top: 0;
}

.purchasedItem__text p:last-child {
	margin-bottom: 0;
}

.unavailableItem {
	font-weight: bold;
	font-size: 20px;
	margin-top: 10px;
	color: var(--color-error);
}

@media screen and (max-width: 600px) {
	.item {
		padding: 10px;
		display: block;
	}

	.item__imageWrapper {
		width: 100%;
		margin-bottom: 10px;
	}

	.item__info {
		margin-right: 0;
	}

	.item__title {
		font-size: 20px;
	}
}
